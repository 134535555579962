import React from 'react';

import CookieConsentLink from '@components/Layout/Footer/atoms/CookieConsentLink';
import Star from 'public/static/premium/star.svg';
import { langs } from 'types/lang';

export interface SubMenuItem {
  label: string;
  link: string;
  classNames?: string;
}

export const categories = ['regular', 'advertise', 'mid', 'footer', 'none'] as const;
export const desktopCategories = ['section', 'media', 'ad'] as const;

export type MobileCategory = (typeof categories)[number];
export type DesktopCategory = (typeof desktopCategories)[number];

export type MenuItem = {
  label: string;
  version: number;
  categoryName?: string;
  icon?: React.FC;
  color?: string;
  pill?: {
    order: number;
    color?: string;
    textColor?: string;
  };
  visibleInLang: Array<keyof typeof langs | string>;
  onDesktop: boolean;
  mobileCategory: MobileCategory;
  desktopCategory: DesktopCategory;
  onMobile: boolean;
  link: string;
  classNames?: string;
  subMenu?: SubMenuItem[];
  Component?: React.FC;
};

export const getMenuItems = (...additionalFilters: ((item: MenuItem) => boolean)[]): MenuItem[] => {
  const menuMap: MenuItem[] = [
    {
      icon: Star,
      label: 'premium',
      version: 1,
      categoryName: 'premium',
      visibleInLang: ['pl'],
      mobileCategory: 'regular',
      desktopCategory: 'section',
      onDesktop: false,
      onMobile: true,
      pill: {
        order: 4,
        color: 'orange-500',
        textColor: 'white',
      },
      link: 'link.premium',
      classNames: 'flex gap-1 w-full items-center mb-3 text-orange-dim',
    },
    {
      icon: Star,
      label: 'premium',
      version: 1,
      categoryName: 'premium',
      visibleInLang: ['pl'],
      mobileCategory: 'regular',
      desktopCategory: 'section',
      // hack: if it's premium but invisible on mobile it's visible on desktop
      onDesktop: false,
      onMobile: false,
      pill: {
        order: 4,
        color: 'orange-500',
        textColor: 'white',
      },
      link: 'link.premium',
    },
    {
      label: 'shipment',
      version: 1,
      visibleInLang: ['pl', 'en', 'es', 'de', 'hu', 'lt'],
      mobileCategory: 'regular',
      categoryName: 'forwarders',
      desktopCategory: 'section',
      onDesktop: true,
      onMobile: true,
      pill: {
        order: 1,
      },
      link: 'link.shipment',
      classNames: '',
    },
    {
      label: 'transport',
      version: 1,
      visibleInLang: ['pl', 'en', 'es', 'de', 'hu', 'lt', 'ro', 'ru', 'ua'],
      mobileCategory: 'regular',
      categoryName: 'transport',
      desktopCategory: 'section',
      onDesktop: true,
      onMobile: true,
      pill: {
        order: 2,
      },
      link: 'link.transport',
      classNames: '',
    },
    {
      label: 'logistics',
      version: 1,
      visibleInLang: ['pl', 'en', 'es', 'de', 'hu', 'lt', 'ro', 'ru', 'ua'],
      mobileCategory: 'regular',
      categoryName: 'logistics',
      desktopCategory: 'section',
      onDesktop: true,
      onMobile: true,
      pill: {
        order: 3,
      },
      link: 'link.logistics',
      classNames: '',
    },
    /*
    {
      label: 'trucking',
      visibleInLang: ['pl', 'en', 'hu', 'lt', 'ro', 'ru', 'de', 'ua'],
      category: 'regular',
      categoryName: 'trucking',
      onDesktop: true,
      onMobile: false,
      link: 'link.trucking',
      classNames: '',
    },
    */
    {
      label: 'trucking',
      version: 1,
      visibleInLang: ['en', 'hu', 'lt', 'ro', 'ru', 'de', 'ua'],
      mobileCategory: 'regular',
      categoryName: 'trucking',
      desktopCategory: 'section',
      onDesktop: false,
      onMobile: false,
      link: 'link.trucking',
      classNames: '',
      pill: {
        order: 3,
      },
    },
    {
      label: 'analitics',
      version: 1,
      visibleInLang: ['ua'],
      categoryName: 'analitics',
      mobileCategory: 'regular',
      desktopCategory: 'section',
      onDesktop: true,
      onMobile: true,
      link: 'link.analitics',
      classNames: '',
    },
    {
      label: 'interviews',
      version: 1,
      visibleInLang: ['ua'],
      categoryName: 'interviews',
      desktopCategory: 'media',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.interviews',
      classNames: '',
    },
    {
      label: 'interviews',
      version: 1,
      visibleInLang: ['pl', 'en', 'de', 'lt', 'hu', 'ro', 'ru'],
      categoryName: 'interviews',
      desktopCategory: 'media',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.interviews',
      classNames: '',
    },
    {
      label: 'magazine',
      version: 1,
      visibleInLang: ['de', 'lt'],
      categoryName: 'magazine',
      desktopCategory: 'media',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.magazine',
      classNames: '',
    },
    {
      label: 'webinars',
      version: 1,
      visibleInLang: ['pl'],
      categoryName: 'webinars',
      desktopCategory: 'media',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.webinars',
      classNames: '',
    },
    {
      label: 'startups',
      version: 1,
      visibleInLang: ['de', 'ru', 'lt'],
      categoryName: 'startups',
      desktopCategory: 'media',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.startups',
      classNames: '',
    },
    {
      label: 'video',
      version: 1,
      visibleInLang: ['pl', 'lt', 'de', 'ua'],
      categoryName: 'video',
      desktopCategory: 'media',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.video',
      classNames: '',
    },
    {
      label: 'podcast',
      version: 1,
      link: 'link.podcast',
      categoryName: 'podcast',
      visibleInLang: ['pl'],
      desktopCategory: 'media',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'podcast',
      version: 1,
      link: 'menu.podcast',
      categoryName: 'podcast',
      visibleInLang: ['en', 'de', 'hu', 'lt', 'ro', 'ru', 'ua'],
      mobileCategory: 'none',
      desktopCategory: 'media',
      onDesktop: false,
      onMobile: false,
    },
    {
      label: 'technology',
      version: 1,
      visibleInLang: ['ru', 'ua'],
      categoryName: 'technology',
      desktopCategory: 'section',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.technology',
      classNames: '',
    },
    {
      label: 'authors',
      version: 1,
      visibleInLang: ['en', 'es', 'ro', 'lt', 'ru', 'ua'],
      categoryName: 'authors',
      desktopCategory: 'media',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.authors',
      classNames: '',
    },
    {
      label: 'opinion',
      version: 1,
      visibleInLang: ['de'],
      categoryName: 'opinions',
      desktopCategory: 'media',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.opinion',
      classNames: '',
    },
    /*
    {
      label: 'advertise',
      version: 1,
      visibleInLang: ['de', 'hu', 'lt', 'ro', 'ru'],
      mobileCategory: 'advertise',
      desktopCategory: 'ad',
      onDesktop: true,
      onMobile: true,
      link: 'link.advertise',
      classNames: 'font-bold',
    },
    */
    {
      label: 'advertise',
      version: 1,
      visibleInLang: ['pl', 'en', 'de', 'es', 'hu', 'lt', 'ro', 'ua'],
      mobileCategory: 'advertise',
      desktopCategory: 'ad',
      onDesktop: true,
      onMobile: false,
      link: 'link.advertise',
      classNames: 'font-bold',
      subMenu: [
        {
          label: 'advertiseBanner',
          link: 'link.advertiseBanner',
        },
        {
          label: 'advertiseArticle',
          link: 'link.advertiseArticle',
        },
      ],
    },
    {
      label: 'advertiseBanner',
      version: 1,
      link: 'link.advertiseBanner',
      desktopCategory: 'ad',
      mobileCategory: 'advertise',
      visibleInLang: ['pl', 'en', 'de', 'es', 'hu', 'lt', 'ro', 'ua'],
      onDesktop: false,
      onMobile: true,
      classNames: 'menu-list-catchy-gradient',
    },
    {
      label: 'advertiseArticle',
      version: 1,
      link: 'link.advertiseArticle',
      visibleInLang: ['pl', 'en', 'de', 'es', 'hu', 'lt', 'ro', 'ua'],
      desktopCategory: 'ad',
      mobileCategory: 'advertise',
      onDesktop: false,
      onMobile: true,
      classNames: 'menu-list-catchy-gradient',
    },
    {
      label: 'hot',
      version: 1,
      link: 'link.hot',
      visibleInLang: ['pl', 'en', 'de', 'lt', 'hu', 'ro', 'ru'],
      mobileCategory: 'mid',
      desktopCategory: 'section',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'contact',
      version: 1,
      link: 'link.contact',
      visibleInLang: ['pl', 'en', 'es', 'de', 'lt', 'hu'],
      mobileCategory: 'mid',
      desktopCategory: 'section',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'newsletter',
      version: 1,
      link: 'link.newsletter',
      visibleInLang: ['pl', 'en', 'es', 'de', 'ua', 'lt', 'ru'],
      mobileCategory: 'mid',
      desktopCategory: 'section',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'newsletterAd',
      version: 1,
      link: 'link.newsletterAd',
      visibleInLang: ['pl', 'en'],
      mobileCategory: 'advertise',
      desktopCategory: 'ad',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'impressum',
      version: 1,
      link: 'link.impressum',
      visibleInLang: ['de'],
      mobileCategory: 'footer',
      desktopCategory: 'section',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'policy',
      version: 1,
      link: 'link.policy',
      visibleInLang: ['pl', 'en', 'es', 'de', 'lt', 'hu', 'ro', 'ru'],
      mobileCategory: 'footer',
      desktopCategory: 'section',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'rules',
      version: 1,
      link: 'link.rules',
      visibleInLang: ['pl', 'en', 'es', 'de', 'lt', 'hu', 'ro', 'ru'],
      mobileCategory: 'footer',
      desktopCategory: 'section',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: '',
      version: 1,
      link: '',
      visibleInLang: ['pl', 'en', 'es', 'de', 'lt', 'hu', 'ro', 'ru'],
      mobileCategory: 'footer',
      desktopCategory: 'section',
      onDesktop: false,
      onMobile: true,
      classNames: '',
      Component: CookieConsentLink,
    },
    {
      icon: Star,
      label: 'premium',
      version: 2,
      categoryName: 'premium',
      visibleInLang: ['pl'],
      mobileCategory: 'regular',
      desktopCategory: 'section',
      onDesktop: false,
      onMobile: true,
      pill: {
        order: 4,
        color: 'orange-500',
        textColor: 'white',
      },
      link: 'link.premium',
      classNames: 'flex gap-1 w-full items-center mb-3 text-orange-dim',
    },
    {
      icon: Star,
      label: 'premium',
      version: 2,
      categoryName: 'premium',
      visibleInLang: ['pl'],
      mobileCategory: 'regular',
      desktopCategory: 'section',
      // hack: if it's premium but invisible on mobile it's visible on desktop
      onDesktop: true,
      onMobile: false,
      pill: {
        order: 4,
        color: 'orange-500',
        textColor: 'white',
      },
      link: 'link.premium',
    },
    {
      label: 'shipment',
      version: 2,
      visibleInLang: ['pl', 'en', 'es', 'de', 'hu', 'lt'],
      mobileCategory: 'regular',
      categoryName: 'forwarders',
      desktopCategory: 'section',
      onDesktop: true,
      onMobile: true,
      pill: {
        order: 1,
      },
      link: 'link.shipment',
      classNames: '',
    },
    {
      label: 'transport',
      version: 2,
      visibleInLang: ['pl', 'en', 'es', 'de', 'hu', 'lt', 'ro', 'ru', 'ua'],
      mobileCategory: 'regular',
      categoryName: 'transport',
      desktopCategory: 'section',
      onDesktop: true,
      onMobile: true,
      pill: {
        order: 2,
      },
      link: 'link.transport',
      classNames: '',
    },
    {
      label: 'logistics',
      version: 2,
      visibleInLang: ['pl', 'en', 'es', 'de', 'hu', 'lt', 'ro', 'ru', 'ua'],
      mobileCategory: 'regular',
      categoryName: 'logistics',
      desktopCategory: 'section',
      onDesktop: true,
      onMobile: true,
      pill: {
        order: 3,
      },
      link: 'link.logistics',
      classNames: '',
    },
    /*
    {
      label: 'trucking',
      visibleInLang: ['pl', 'en', 'hu', 'lt', 'ro', 'ru', 'de', 'ua'],
      category: 'regular',
      categoryName: 'trucking',
      onDesktop: true,
      onMobile: false,
      link: 'link.trucking',
      classNames: '',
    },
    */
    {
      label: 'trucking',
      version: 2,
      visibleInLang: ['en', 'hu', 'lt', 'ro', 'ru', 'de', 'ua'],
      mobileCategory: 'regular',
      categoryName: 'trucking',
      desktopCategory: 'section',
      onDesktop: false,
      onMobile: false,
      link: 'link.trucking',
      classNames: '',
      pill: {
        order: 3,
      },
    },
    {
      label: 'analitics',
      version: 2,
      visibleInLang: ['ua'],
      categoryName: 'analitics',
      mobileCategory: 'regular',
      desktopCategory: 'section',
      onDesktop: true,
      onMobile: true,
      link: 'link.analitics',
      classNames: '',
    },
    {
      label: 'magazine',
      version: 2,
      visibleInLang: ['pl', 'de', 'lt'],
      categoryName: 'interviews',
      desktopCategory: 'media',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: false,
      link: 'link.magazine',
      classNames: '',
    },
    {
      label: 'interviews',
      version: 2,
      visibleInLang: ['ua'],
      categoryName: 'interviews',
      desktopCategory: 'media',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.interviews',
      classNames: '',
    },
    {
      label: 'interviews',
      version: 2,
      visibleInLang: ['pl', 'en', 'de', 'lt', 'hu', 'ro', 'ru'],
      categoryName: 'interviews',
      desktopCategory: 'media',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.interviews',
      classNames: '',
    },
    {
      label: 'webinars',
      version: 2,
      visibleInLang: ['pl'],
      categoryName: 'webinars',
      desktopCategory: 'media',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.webinars',
      classNames: '',
    },
    {
      label: 'startups',
      version: 2,
      visibleInLang: ['de', 'ru', 'lt'],
      categoryName: 'startups',
      desktopCategory: 'media',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.startups',
      classNames: '',
    },
    {
      label: 'video',
      version: 2,
      visibleInLang: ['pl', 'lt', 'de', 'ua'],
      categoryName: 'video',
      desktopCategory: 'media',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.video',
      classNames: '',
    },
    {
      label: 'podcast',
      version: 2,
      visibleInLang: ['pl'],
      categoryName: 'podcast',
      desktopCategory: 'media',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.podcast',
      classNames: '',
    },
    {
      label: 'podcast',
      version: 2,
      link: 'menu.podcast',
      categoryName: 'podcast',
      visibleInLang: ['en', 'de', 'hu', 'lt', 'ro', 'ru', 'ua'],
      mobileCategory: 'none',
      desktopCategory: 'media',
      onDesktop: false,
      onMobile: false,
    },
    {
      label: 'technology',
      version: 2,
      visibleInLang: ['ru', 'ua'],
      categoryName: 'technology',
      desktopCategory: 'section',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.technology',
      classNames: '',
    },
    {
      label: 'authors',
      version: 2,
      visibleInLang: ['en', 'es', 'ro', 'lt', 'ru', 'ua'],
      categoryName: 'authors',
      desktopCategory: 'media',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.authors',
      classNames: '',
    },
    {
      label: 'opinion',
      version: 2,
      visibleInLang: ['de'],
      categoryName: 'opinions',
      desktopCategory: 'media',
      mobileCategory: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.opinion',
      classNames: '',
    },
    /*
    {
      label: 'advertise',
      version: 2,
      visibleInLang: ['de', 'hu', 'lt', 'ro', 'ru'],
      mobileCategory: 'advertise',
      desktopCategory: 'ad',
      onDesktop: true,
      onMobile: true,
      link: 'link.advertise',
      classNames: 'font-bold',
    },
    */
    {
      label: 'advertise',
      version: 2,
      visibleInLang: ['pl', 'en', 'de', 'es', 'hu', 'lt', 'ro', 'ua'],
      mobileCategory: 'advertise',
      desktopCategory: 'ad',
      onDesktop: true,
      onMobile: false,
      link: 'link.advertise',
      classNames: 'font-bold',
      subMenu: [
        {
          label: 'advertiseBanner',
          link: 'link.advertiseBanner',
        },
        {
          label: 'advertiseArticle',
          link: 'link.advertiseArticle',
        },
      ],
    },
    {
      label: 'advertiseBanner',
      version: 2,
      link: 'link.advertiseBanner',
      desktopCategory: 'ad',
      mobileCategory: 'advertise',
      visibleInLang: ['pl', 'en', 'de', 'es', 'hu', 'lt', 'ro', 'ua'],
      onDesktop: false,
      onMobile: true,
      classNames: 'menu-list-catchy-gradient',
    },
    {
      label: 'advertiseArticle',
      version: 2,
      link: 'link.advertiseArticle',
      visibleInLang: ['pl', 'en', 'de', 'es', 'hu', 'lt', 'ro', 'ua'],
      desktopCategory: 'ad',
      mobileCategory: 'advertise',
      onDesktop: false,
      onMobile: true,
      classNames: 'menu-list-catchy-gradient',
    },
    {
      label: 'hot',
      version: 2,
      link: 'link.hot',
      visibleInLang: ['pl', 'en', 'de', 'lt', 'hu', 'ro', 'ru'],
      mobileCategory: 'mid',
      desktopCategory: 'section',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'contact',
      version: 2,
      link: 'link.contact',
      visibleInLang: ['pl', 'en', 'es', 'de', 'lt', 'hu'],
      mobileCategory: 'mid',
      desktopCategory: 'section',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'newsletter',
      version: 2,
      link: 'link.newsletter',
      visibleInLang: ['pl', 'en', 'es', 'de', 'ua', 'lt', 'ru'],
      mobileCategory: 'mid',
      desktopCategory: 'section',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'newsletterAd',
      version: 2,
      link: 'link.newsletterAd',
      visibleInLang: ['pl', 'en'],
      mobileCategory: 'advertise',
      desktopCategory: 'ad',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'impressum',
      version: 2,
      link: 'link.impressum',
      visibleInLang: ['de'],
      mobileCategory: 'footer',
      desktopCategory: 'section',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'policy',
      version: 2,
      link: 'link.policy',
      visibleInLang: ['pl', 'en', 'es', 'de', 'lt', 'hu', 'ro', 'ru'],
      mobileCategory: 'footer',
      desktopCategory: 'section',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'rules',
      version: 2,
      link: 'link.rules',
      visibleInLang: ['pl', 'en', 'es', 'de', 'lt', 'hu', 'ro', 'ru'],
      mobileCategory: 'footer',
      desktopCategory: 'section',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: '',
      version: 2,
      link: '',
      visibleInLang: ['pl', 'en', 'es', 'de', 'lt', 'hu', 'ro', 'ru'],
      mobileCategory: 'footer',
      desktopCategory: 'section',
      onDesktop: false,
      onMobile: true,
      classNames: '',
      Component: CookieConsentLink,
    },
  ];

  return menuMap.filter((obj) => {
    return additionalFilters.map((filter) => filter(obj)).every((result) => result);
  });
};
