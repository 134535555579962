import {
  FaFacebookF,
  FaLinkedinIn,
  FaYoutube,
  FaXing,
  FaGlobeAmericas,
  FaWhatsapp,
  FaFacebookMessenger,
  FaVk,
  FaTelegramPlane,
  FaViber,
  FaEnvelopeOpenText,
} from 'react-icons/fa';
import { RiTwitterXFill } from 'react-icons/ri';

import { SocialsType } from 'types/socials';

export const socialsIcons = {
  facebook: FaFacebookF,
  linkedin: FaLinkedinIn,
  twitter: RiTwitterXFill,
  www: FaGlobeAmericas,
  xing: FaXing,
  messenger: FaFacebookMessenger,
  whatsapp: FaWhatsapp,
  vk: FaVk,
  telegram: FaTelegramPlane,
  viber: FaViber,
  mail: FaEnvelopeOpenText,
};

export const socialsConfig: SocialsType = {
  tt: {
    links: {
      en: 'https://x.com/TransInfoENG1',
    },
    icon: RiTwitterXFill,
  },
  fb: {
    links: {
      pl: 'https://www.facebook.com/trans.infoPoland',
      en: 'https://www.facebook.com/trans.infoGLOBAL',
      es: 'https://www.facebook.com/profile.php?id=61573574327330',
      de: 'https://www.facebook.com/trans.infoGermany',
      hu: 'https://www.facebook.com/trans.infoHungary',
      lt: 'https://www.facebook.com/trans.infoLithuania',
      ro: 'https://www.facebook.com/trans.infoRomania',
      ru: 'https://www.facebook.com/trans.infoEast',
      ua: 'https://www.facebook.com/trans.info.news.ua',
    },
    icon: FaFacebookF,
  },
  yt: {
    links: {
      pl: 'https://www.youtube.com/channel/UC2w2lDT1nMDvv8P_ZP1SPJQ',
      en: 'https://www.youtube.com/channel/UCKz-nh5aje8ODJhQyFXb2VQ',
      de: 'https://www.youtube.com/@transiNFO-DE',
    },
    icon: FaYoutube,
  },
  li: {
    links: {
      pl: 'https://www.linkedin.com/showcase/trans-info-poland/',
      en: 'https://www.linkedin.com/company/18219049',
      de: 'https://www.linkedin.com/showcase/trans-info-dach',
      lt: 'https://www.linkedin.com/showcase/trans-info-lithuania',
      ro: 'https://www.linkedin.com/showcase/trans-info-romania',
      ru: 'https://www.linkedin.com/showcase/trans-info-russia',
      ua: 'https://www.linkedin.com/company/trans-info-ua/',
    },
    icon: FaLinkedinIn,
  },
  vk: {
    links: {
      ru: 'https://vk.com/portaltransinfo',
    },
    icon: FaVk,
  },
  telegram: {
    links: {
      ru: 'https://t.me/transinforu',
      ua: 'https://t.me/trans_info_UA',
    },
    icon: FaTelegramPlane,
  },
  xing: {
    links: {},
    icon: FaXing,
  },
  messenger: {
    links: {
      pl: 'https://m.me/trans.infoPoland',
      en: 'https://m.me/trans.infoGLOBAL',
      de: 'https://m.me/trans.infoGermany',
      ru: 'https://m.me/trans.infoRussia',
      lt: 'https://m.me/trans.infoLithuania',
      ro: 'https://m.me/trans.infoRomania',
      hu: 'https://m.me/trans.infoHungary',
    },
    icon: FaFacebookMessenger,
  },
  newsletter: {
    links: {
      de: '/de/newsletter',
      ua: '/ua/newsletter',
      lt: '/lt/newsletter',
      pl: '/pl/newsletter',
      ru: '/ru/newsletter',
    },
    icon: FaEnvelopeOpenText,
    target: '_self',
  },
};
